.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App-logo {
    height: 30vmin;
}

.App-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #404040;
}

.App-link {
    color: #61dafb;
}

.link {
    color: #2f77ce
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}